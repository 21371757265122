<template>
  <div class="navview-pane">
    <div class="navview-container">
      <div class="bg-darkRed flex align-items-left justify-content-left">
        <button class="pull-button m-0 bg-red-hover">
          <span class="mif-menu fg-white"></span>
        </button>
        <h2 class="text-light m-0 fg-white pl-4" style="line-height: 52px">E<span class="fg-yellow">N</span>AT.AT</h2>
      </div>
      <div :class="systemLabelBackground + ' text-center text-small data-box'">
        <p :class="systemLabelForeground + ' text-small m-0'" style="line-height: 14px">
          {{ systemLabel }}
        </p>
      </div>
      <!-- SYSTEM STATUS -->
      <div id="sideMenuSystemStatus" class="suggest-box">
        <div class="data-box">
          <!-- <div class="data-box"> -->
          <img class="avatar" src="@/assets/images/enat.png" />
          <div class="ml-3 avatar-title flex-column">
            <a class="block fg-white text-medium no-decor" href="#"
              ><span
                id="sideMenuSystemStatusCustomerName"
                class="reduce-1"
                v-tooltip="
                  customerDesc +
                  '\nAktive Verbindungen: ' + connectionCount + 
                  '\nVerbindungszeit: ' + connectionTime + 
                  '\nServerzeit: ' + serverTime
                "
                >{{ customerName }}</span
              ></a
            >
            <span id="sideMenuSystemStatusCustomerDesc" class="text-small">{{ customerDesc }}</span>
            <br />
            <span id="sideMenuSystemClock" class="fg-green mr-2">●</span>
            <span id="sideMenuSystemClockText" class="text-small">{{ dateTime }}</span>
            <br />
            <span
              id="sideMenuSystemStatusColor"
              class="fg-green mr-2"
              v-if="systemStatus === 'System online'"
              >●</span
            >
            <span id="sideMenuSystemStatusColor" class="fg-red mr-2" v-else
              >●</span
            >
            <span id="sideMenuSystemStatusText" class="text-small">{{
              systemStatus
            }}</span>
          </div>
          <img class="avatar holder ml-2" src="@/assets/images/enat.png" />
        </div>
      </div>
    </div>
    <!-- MENU CONTAINER -->
    <div class="navview-menu-container">
      <ul class="navview-menu mt-1" id="side-menu">
        <div>
          <div v-if="view_menu !== null">
            <!-- BEDIENUNG MENU -->
            <li class="item-header">Bedienung</li>
            <div
              v-for="entry in view_menu.sort((a, b) =>
                a.index > b.index ? 1 : -1
              )"
              :key="entry._id"
            >
              <li>
                <a
                  :class="[entry.submenu !== null ? 'dropdown-toggle' : '']"
                  :href="entry.path"
                  ><span v-if="entry.icon !== null" class="icon"
                    ><span :class="entry.icon"></span></span
                  ><span class="caption">{{ entry.label }}</span></a
                >
              </li>

              <ul class="navview-menu stay-open" data-role="dropdown">
                <li v-for="subEntry in entry.submenu" :key="subEntry.id">
                  <a :href="subEntry.path"
                    ><span v-if="subEntry.icon !== undefined" class="icon"
                      ><span :class="subEntry.icon"></span></span
                    ><span class="caption">{{ subEntry.label }}</span></a
                  >
                </li>
              </ul>
            </div>
          </div>

          <!-- ZENTRAL MENU -->
          <li class="item-header">Zentral</li>
          <li>
            <router-link to="/dashboard2">
              <a
                ><span class="icon"><span class="mif-dashboard"></span></span
                ><span class="caption">Dashboard</span></a
              >
            </router-link>
          </li>
          <li>
            <router-link to="/weather">
              <a
                ><span class="icon"><span class="mif-weather"></span></span
                ><span class="caption">Wetterstation</span></a
              >
            </router-link>
          </li>
          <li>
            <a onclick="mainDesktop('boot')" href="#"
              ><span class="icon"><span class="mif-watch"></span></span
              ><span class="caption">Standort / Uhr</span></a
            >
          </li>
          <li>
            <router-link to="/tablealarm">
              <a href="#"
                ><span class="icon"><span class="mif-bell"></span></span
                ><span class="caption">Alarmliste</span></a
              >
            </router-link>
          </li>

          <!-- EINSTELLUNGEN MENU -->
          <li class="item-header">Einstellungen</li>
          <!-- SYSTEMEINSTELLUNGEN UNTERMENU -->
          <li>
            <a class="dropdown-toggle" href="#"
              ><span class="icon"><span class="mif-cogs"></span></span
              ><span class="caption">Systemeinstellungen</span></a
            >
          </li>
          <ul class="navview-menu stay-open" data-role="dropdown">
            <li>
              <router-link to="/configsys">
                <a
                  ><span class="icon"><span class="mif-equalizer"></span></span
                  ><span class="caption">System</span></a
                >
              </router-link>
            </li>
            <li>
              <router-link to="/configsysnetwork">
                <a
                  ><span class="icon"><span class="mif-satellite"></span></span
                  ><span class="caption">Netzwerk</span></a
                >
              </router-link>
            </li>
            <li>
              <router-link to="/configsysnotifications">
                <a
                  ><span class="icon"><span class="mif-comment"></span></span
                  ><span class="caption">Benachrichtigungen</span></a
                >
              </router-link>
            </li>
          </ul>

          <!-- Seiten und Menüs UNTERMENU -->
          <li>
            <a class="dropdown-toggle" href="#"
              ><span class="icon"><span class="mif-open-book"></span></span
              ><span class="caption">Seiten und Menüs</span></a
            >
          </li>
          <ul class="navview-menu stay-open" data-role="dropdown">
            <li>
              <router-link to="/menutable">
                <a
                  ><span class="icon"><span class="mif-menu"></span></span
                  ><span class="caption">Menü</span></a
                >
              </router-link>
            </li>
            <li>
              <router-link to="/configsysnetwork">
                <a
                  ><span class="icon"
                    ><span class="mif-files-empty"></span></span
                  ><span class="caption">Seiten</span></a
                >
              </router-link>
            </li>
            <li>
              <router-link to="/configsysnotifications">
                <a
                  ><span class="icon"><span class="mif-comment"></span></span
                  ><span class="caption">Benachrichtigungen</span></a
                >
              </router-link>
            </li>
          </ul>

          <li>
            <router-link to="/table3">
              <a
                ><span class="icon"><span class="mif-table"></span></span
                ><span class="caption">Parametereinstellungen</span></a
              >
            </router-link>
          </li>
          <li>
            <router-link to="/usertable">
              <a
                ><span class="icon"><span class="mif-users"></span></span
                ><span class="caption">Benutzer</span></a
              >
            </router-link>
          </li>
          <li>
            <router-link to="/iotable">
              <a
                ><span class="icon"><span class="mif-flow-tree"></span></span
                ><span class="caption">IO-Konfiguration</span></a
              >
            </router-link>
          </li>
          <li>
            <router-link to="/typestable">
              <a
                ><span class="icon"><span class="mif-cabinet"></span></span
                ><span class="caption">Typen-Konfiguration</span></a
              >
            </router-link>
          </li>
          <li>
            <router-link to="/systemLog">
              <a
                ><span class="icon"><span class="mif-list"></span></span
                ><span class="caption">System Log</span></a
              >
            </router-link>
          </li>
          <li>
            <router-link to="/terminal">
              <a
                ><span class="icon"><span class="mif-keyboard"></span></span
                ><span class="caption">Terminal</span></a
              >
            </router-link>
          </li>
        </div>
      </ul>
    </div>
    <!-- COPYRIGHT / VERSION INFO -->
    <div
      id="sideMenuCopyrightInfo"
      class="w-100 text-center text-small data-box p-2 border-top bd-grayMouse"
      style="position: absolute; bottom: 0"
    >
      <div>
        &copy;{{ copyrightYear }}
        <a
          class="text-muted fg-white-hover no-decor"
          href="mailto:service@elektronation.at"
          >{{ copyrightTxt }}</a
        >
      </div>
      <div>{{ versionInfo }}</div>
    </div>
  </div>
</template>

<script>
import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
import store from "@/store";
import { dhm } from "../helpers";

export default {
  name: "navBar",
  props: {
    msg: String,
    title: String,
    name2: String,
    desc2: String,
    dasObject: Object,
  },
  data: function () {
    return {
      title2: "Kackhaufen",
      loading: false,
      view_menu: null,
      desc: null,
      // dateTime: null,
      sysStat: null,
      // clientData: null,
      // versionInfo: null,
      bedienungMenu: null,
      interval: undefined,
    };
  },
  computed: {
    sortMenu() {
      // return this.view_menu ? _.sortBy(this.view_menu, "index") : [];
      return null;
    },
    dateTime: function () {
      if (store.getters.getDateTime !== null) {
        return store.getters.getDateTime;
      } else {
        return "???";
      }
    },
    clientData: function () {
      return this.$store.getters.getClientData;
    },
    customerName: function () {
      if (store.getters.getClientData.installation.customer.name !== null) {
        return store.getters.getClientData.installation.customer.name;
      } else {
        return "???";
      }
    },
    customerDesc: function () {
      if (store.getters.getClientData.installation.customer.desc !== null) {
        return store.getters.getClientData.installation.customer.desc;
      } else {
        return "???";
      }
    },
    systemLabel: function () {
      if (store.getters.getClientData.installation.system.label !== null) {
        return store.getters.getClientData.installation.system.label;
      } else {
        return "???";
      }
    },
    systemLabelBackground: function () {
      if (store.getters.getClientData.installation.system.background !== null) {
        return "bg-" + store.getters.getClientData.installation.system.background;
      } else {
        return "bg-white";
      }
    },
    systemLabelForeground: function () {
      if (store.getters.getClientData.installation.system.foreground !== null) {
        return "fg-" + store.getters.getClientData.installation.system.foreground;
      } else {
        return "fg-black";
      }
    },
    systemStatus: function () {
      if (this.$store.getters.getSystemStatus !== "System online") {
        this.$toast.add({
          severity: "error",
          summary: "Verbindungsfehler",
          detail: "Die Verbindung zum Klimacomputer ist gestört!",
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: "success",
          summary: "Verbindung hergestellt",
          detail: "Die Verbindung zum Klimacomputer wurde erfolgreich hergestellt.",
          life: 3000,
        });
      }
      return this.$store.getters.getSystemStatus;
    },
    connectionCount: function () {
      if (this.$store.getters.getActiveConnections !== null) {
        return this.$store.getters.getActiveConnections.connections;
      } else {
        return 0;
      }
    },
    connectionTime: function () {
      if (this.$store.getters.getActiveConnections !== null) {
        return dhm(this.$store.getters.getActiveConnections.endDate - this.$store.getters.getActiveConnections.startDate);
      } else {
        return dhm(0);
      }
    },
    serverTime: function () {
      if (this.$store.getters.getActiveConnections.server !== null) {
        return dhm(this.$store.getters.getActiveConnections.server.endDate - this.$store.getters.getActiveConnections.server.startDate);
      } else {
        return dhm(0);
      }
    },
    versionInfo: function () {
      if (store.getters.getClientData.copyright.versionInfo !== null) {
        return store.getters.getClientData.copyright.versionInfo;
      } else {
        return "???"
      }
    },
    copyrightYear: function () {
      if (store.getters.getClientData.copyright.copyrightYear !== null) {
        const dt = new Date();
        const yrNow = dt.getFullYear();
        const yr = store.getters.getClientData.copyright.copyrightYear;
        if (yr == yrNow) {
          return yr;
        } else {
          return yr + "-" + yrNow;
        }
      } else {
        return "???"
      }
    },
    copyrightTxt: function () {
      if (store.getters.getClientData.copyright !== null) {
        return store.getters.getClientData.copyright.copyrightTxt;
      } else {
        return "???"
      }
    }
  },
  watch: {
    title2: function (value, oldValue) {
      console.log("New Value: " + value);
      console.log("Old Value: " + oldValue);
    },
  },
  methods: {
    micheGeclickd(einObject, zweiObject) {
      alert("miche gelickd!" + einObject + zweiObject.desc);
    },
    // getSystemStatus() {
    //   mainService
    //     .getSystemStatus()
    //     .then((response) => {
    //       //console.log('server: ', response.data)
    //       this.dateTime = response.data.dateTime;
    //       this.sysStat = response.data.status;
    //       this.versionInfo = response.data.copyright;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getSystemStatus() {
      // this.dateTime = this.$store.getters.getDateTime;
      // this.clientData = this.$store.getters.getClientData;
    },
    registerSocketIoEvents() {
      SocketioService.socket.on("system_config", (data) => {
        // console.log(data);
        if (data !== null) {
          // document not changed
          if (data !== null) {
            this.versionInfo = data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "IO-Eintrag nicht durchgeführt",
              detail:
                "modifiziert: " +
                data.modifiedCount +
                " , Erstellt: " +
                data.upsertedCount +
                " [" +
                data.upsertedId +
                "]",
              life: 3000,
            });
          }
        }
      });
    },
    getMenuList() {
      store.dispatch("setLoading", true);
      this.loading = true;
      SocketioService.getServerData("view", "menu", (err, response) => {
        this.view_menu = response;
        // Object.entries(this.view_menu).forEach(([, value], index) => {
        //   if (index === 0) {
        //     this.view_menu_selection[0] = "ROOT";
        //   }
        //   this.view_menu_selection[index + 1] = value.label;
        //   this.view_menu_index.push(index);
        // });
        // console.log(this.view_menu_selection);
        this.loading = false;
        store.dispatch("setLoading", false);
      });
    },
    getBedienungMenu() {
      this.loading = true;
      mainService
        .getBedienungMenu()
        .then((response) => {
          //console.log('server: ', response.data)
          this.bedienungMenu = response.data.mainMenu;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSystemConfigCopyright() {
      this.loading = true;
      SocketioService.getSystemConfigCopyright((response) => {
        this.versionInfo = response;
        this.loading = false;
      });
    },
  },
  mounted() {
    // this.getSystemStatus();
    // this.getBedienungMenu();
    // this.registerSocketIoEvents();
    // this.getSystemConfigCopyright();
  },
  created() {
    this.getSystemStatus();
    this.getMenuList();
    // this.getBedienungMenu();
    this.registerSocketIoEvents();
    // this.getSystemConfigCopyright();
    // this.getSystemStatus();
    // this.interval = setInterval(() => {
    //   this.getSystemStatus();
    // }, 1000);
  },
};
</script>

<style scoped>
.navview .navview-pane {
  background-color: #222d32;
  color: #b8c7ce;
  font-size: 14px;
}
.navview .pull-button {
  height: 52px;
  width: 52px;
}
.navview .navview-menu {
  background-color: #222d32;
}
.navview .navview-menu .stay-open a {
  padding-left: 0.5em;
}
.navview .navview-menu li a:hover, .navview .navview-menu li.active a:hover {
  color: #fff;
  background: #6a8d9b;
}
.navview .navview-menu li.active a {
  color: #fff;
  background-color: #ce352c !important;
}
.navview .navview-menu .dropdown-toggle::after {
  border-bottom-color: white !important;
}
.navview li.active-toggle.active-control .dropdown-toggle::after {
  border-bottom-color: yellow !important;
}
.navview .navview-menu .item-header {
  color: #4b646f;
  background: #1d1515;
  font-size: 12px;
}
.navview .suggest-box .holder:hover {
  color: #fff;
  background: #1e282c;
}
.navview .suggest-box img.holder {
  width: 32px !important;
  height: auto !important;
}
.navview .navview-menu .navview-menu {
  background-color: #1a2226;
}
.navview .pull-button + h2,
.navview.compacted .pull-button + h2 {
  display: none;
}
.navview.expand .pull-button + h2,
.navview-pane.open .pull-button + h2 {
  display: flex;
}
@media screen and (min-width: 1200px) {
  .navview .pull-button + h2 {
    display: flex;
  }
}
.navview-pane ::-webkit-scrollbar-track {
  background-color: #4b646f !important;
}
.navview .navview-menu li.active::before {
  content: "";
  display: block;
  position: absolute;
  height: 24px;
  top: 8px;
  left: 0;
  width: 4px;
  background-color: yellow !important;
  z-index: 1;
}
.navview li.active-toggle.active-control {
  /* background-color: #000000 !important; */
  background-color: #1a2226;
  color: yellow;
}
.navview li.active-toggle.active-control a:hover {
  background-color: #6a8d9b !important;
}
</style>